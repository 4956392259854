.clickable {
  cursor: pointer;
}

.error {
  color: red;
}

.border {
  border-width: 2px;
  border-style: outset;
  border-color: darkgray;
  margin: 4px;
  margin-bottom: 8px;
  padding: 4px;
}